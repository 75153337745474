"use client";

import { Tooltip } from "@mantine/core";
import { type ReactElement } from "react";
import { elementConfig } from "@/app/_utils/configs/element/config";
import { themeConfig } from "@/app/_utils/configs/theme/config";
import { TooltipDesign } from "@/app/_utils/designs/Tooltip/design";
import { getRandomIndexFunc } from "@/app/_utils/funcs/getRandomIndex/func";
import { type ThemeType } from "@/app/_utils/types";
import { SvgComputerComp } from "../SvgComputer/comp";
import { SvgDevicesComp } from "../SvgDevices/comp";
import { SvgIdeaComp } from "../SvgIdea/comp";
import { SvgPhoneComp } from "../SvgPhone/comp";
import { SvgTabletComp } from "../SvgTablet/comp";
import { changeThemeFunc } from "./func";

export const ButtonComp = ({ label }: { label: string }): ReactElement => (
    <Tooltip
        label={label}
        {...TooltipDesign}
        position="top"
    >
        <div className="scale-[0.55] rounded-full bg-gradient-to-b from-chroma-700 to-neutral-500 to-60% p-[2px] opacity-60 transition-[transform,shadow] duration-500 has-[button:hover]:scale-[0.60] has-[button:hover]:opacity-90 lg:scale-[0.85] lg:has-[button:hover]:scale-[0.9]">
            <div className="flex h-fit w-fit flex-col items-center justify-center rounded-full bg-black p-6">
                <button
                    aria-label={label}
                    className="absolute z-10 h-full w-full cursor-pointer rounded-full focus-visible:outline-dashed focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-chroma-700"
                    onClick={() => {
                        const element = document.getElementById(elementConfig.page.id);
                        if (element) {
                            element.classList.add(
                                "animate-shake",
                                "animate-once",
                                "animate-duration-100",
                            );

                            setTimeout(() => {
                                element.classList.remove(
                                    "animate-shake",
                                    "animate-once",
                                    "animate-duration-100",
                                );
                            }, 100);
                        }

                        changeThemeFunc(
                            getRandomIndexFunc(themeConfig.allowedThemes) as ThemeType,
                        );
                    }}
                />
                <div className="relative flex flex-col items-center gap-y-4">
                    <div className="h-12 w-12">
                        <SvgDevicesComp />
                        <SvgComputerComp />
                        <SvgTabletComp />
                        <SvgPhoneComp />
                    </div>
                    <div className="flex flex-col gap-y-1">
                        <div className="h-1 w-[2px] bg-chroma-600" />
                        <div className="h-1 w-[2px] bg-chroma-500" />
                        <div className="h-1 w-[2px] bg-neutral-500" />
                        <div className="h-1 w-[2px] bg-neutral-600" />
                    </div>
                    <div className="h-20 w-20">
                        <SvgIdeaComp />
                    </div>
                </div>
            </div>
        </div>
    </Tooltip>
);
