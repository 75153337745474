"use client";

import { Tooltip } from "@mantine/core";
import confetti from "canvas-confetti";
import { type ReactElement, useState } from "react";
import useSound from "use-sound";
import { TooltipDesign } from "@/app/_utils/designs/Tooltip/design";

const fillRedClassNames = [
    "fill-red-500/0",
    "fill-red-500/10",
    "fill-red-500/20",
    "fill-red-500/30",
    "fill-red-500/40",
    "fill-red-500/50",
    "fill-red-500/60",
    "fill-red-500/70",
    "fill-red-500/80",
    "fill-red-500/90",
    "fill-red-500",
];

const animationClassNames = [
    "animate-float",
    "animate-heartbeats",
];

export const ButtonHeartComp = ({
    clickMe,
    label,
}: {
    label: string;
    clickMe: string;
}): ReactElement => {
    const [
        playbackRate,
        setPlaybackRate,
    ] = useState(0.5);

    const [
        indexFillRed,
        setIndexFillRed,
    ] = useState(0);

    const [
        indexAnimation,
        setIndexAnimation,
    ] = useState(0);

    const [playSoundHeartbeat] = useSound("/assets/sounds/heartbeat-v2-101758.mp3", {
        playbackRate,
    });

    const [playSoundHeartbeats] = useSound("/assets/sounds/heartbeat-37783.mp3", {
        volume: 1,
    });

    const [playSoundHearts] = useSound("/assets/sounds/mystical-chime-196405.mp3", {
        volume: 0.5,
    });

    const [playSoundPop] = useSound("/assets/sounds/pop-268648.mp3");

    const handleClick = () => {
        if (indexFillRed < 10) {
            playSoundHeartbeat();
            setPlaybackRate(playbackRate + 0.1);
            setIndexFillRed(indexFillRed + 1);

            if (indexFillRed === 9) {
                playSoundHeartbeats();
                playSoundHearts();
                setIndexAnimation(1);

                confetti({
                    angle: 90,
                    scalar: 2,
                    particleCount: 110,
                    spread: 75,
                    origin: { x: 0.53, y: 0.3 },
                    shapes: [
                        confetti.shapeFromText({ text: "❤️" }),
                    ],
                });

                const timer1 = setTimeout(() => {
                    playSoundPop();
                    setIndexFillRed(0);

                    return () => clearTimeout(timer1);
                }, 3900);

                const timer2 = setTimeout(() => {
                    setIndexAnimation(0);
                    return () => clearTimeout(timer2);
                }, 4900);
            }
        }
    };

    return (
        <div className="relative inline-block text-nowrap">
            <Tooltip
                label={clickMe}
                {...TooltipDesign}
                position="top"
            >
                <button
                    aria-label={clickMe}
                    type="button"
                    className="border-3 absolute inset-x-0 -top-5 bottom-0 z-10 rounded-sm border-transparent bg-red-500/10 focus-visible:outline-dashed focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-700"
                    onClick={handleClick}
                />
            </Tooltip>
            <span className="relative inline-block pt-8 text-red-500">
                <div className="absolute -top-3 left-1/2 -translate-x-1/2 transform">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        className={`h-10 w-10 ${animationClassNames[indexAnimation]} ${fillRedClassNames[indexFillRed]} stroke-red-500 stroke-1`}
                        strokeMiterlimit={10}
                    >
                        <path d="M15.32,2A4,4,0,0,0,12,3.72,4,4,0,0,0,8.68,2a3.91,3.91,0,0,0-4,3.83c0,5.76,7.3,9,7.3,9s7.3-3.19,7.3-8.95A3.91,3.91,0,0,0,15.32,2Z" />
                        <path d="M12,14.78c0,3.65-2.69,4.57-.19,8.22" />
                        <polyline points="9.26 16.61 12 14.78 14.74 16.61" />
                    </svg>
                </div>
                {label}
            </span>
        </div>
    );
};
