import { type ModalProps, ScrollArea } from "@mantine/core";

const headerClassName = "bg-neutral-900";

const titleClassName = "text-neutral-100";

const contentClassName = "bg-neutral-900";

const buttonCloseClassName =
    "mr-[5px] bg-neutral-900/10 stroke-neutral-300 hover:bg-neutral-800 hover:stroke-neutral-100 focus-visible:bg-neutral-900/50 focus-visible:stroke-neutral-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-default-800 stroke-[0.5]";

export const ModalDesign: Pick<
    ModalProps,
    "classNames" | "scrollAreaComponent" | "overlayProps" | "closeButtonProps"
> = {
    classNames: {
        header: headerClassName,
        title: titleClassName,
        content: contentClassName,
    },

    scrollAreaComponent: ScrollArea.Autosize,

    closeButtonProps: {
        className: buttonCloseClassName,
    },

    overlayProps: {
        backgroundOpacity: 0.7,
        blur: 4,
    },
};
