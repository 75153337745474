import { type ReactElement } from "react";
import { SvgDevicesLocalDesign } from "../contants";

export const SvgTabletComp = (): ReactElement => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
        className={`${SvgDevicesLocalDesign.className} hidden md:flex lg:hidden`}
    >
        <g>
            <g>
                <g>
                    <path
                        d="M76.8,392.533c4.71,0,8.533-3.823,8.533-8.533V42.667c0-12.442,13.158-25.6,25.6-25.6h290.133
				c12.442,0,25.6,13.158,25.6,25.6c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533C443.733,20.736,422.997,0,401.067,0
				H110.933C89.003,0,68.267,20.736,68.267,42.667V384C68.267,388.71,72.09,392.533,76.8,392.533z"
                    />
                    <path
                        d="M435.2,68.267H110.933c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533h315.733v384
				c0,12.442-13.158,25.6-25.6,25.6H110.933c-12.442,0-25.6-13.158-25.6-25.6v-42.667h315.733c4.71,0,8.533-3.823,8.533-8.533
				s-3.823-8.533-8.533-8.533H76.8c-4.71,0-8.533,3.823-8.533,8.533v51.2c0,21.931,20.736,42.667,42.667,42.667h290.133
				c21.931,0,42.667-20.736,42.667-42.667V76.8C443.733,72.09,439.91,68.267,435.2,68.267z"
                    />
                    <path
                        d="M256.171,34.133c-4.71,0-8.491,3.823-8.491,8.533c0,4.71,3.866,8.533,8.576,8.533c4.719,0,8.533-3.823,8.533-8.533
				c0-4.71-3.814-8.533-8.533-8.533H256.171z"
                    />
                    <path
                        d="M309.7,121.967c-3.336,3.337-3.336,8.73,0,12.066l68.267,68.267c1.664,1.664,3.849,2.5,6.033,2.5
				c2.185,0,4.369-0.836,6.033-2.5c3.337-3.337,3.337-8.73,0-12.066l-68.267-68.267C318.43,118.63,313.037,118.63,309.7,121.967z"
                    />
                    <path
                        d="M238.933,460.8c0,9.412,7.654,17.067,17.067,17.067s17.067-7.654,17.067-17.067c0-9.412-7.654-17.067-17.067-17.067
				S238.933,451.388,238.933,460.8z"
                    />
                    <path
                        d="M384,153.6c2.185,0,4.369-0.836,6.033-2.5c3.337-3.337,3.337-8.73,0-12.066l-17.067-17.067
				c-3.337-3.336-8.73-3.336-12.066,0c-3.337,3.337-3.337,8.73,0,12.066l17.067,17.067C379.631,152.764,381.815,153.6,384,153.6z"
                    />
                </g>
            </g>
        </g>
    </svg>
);
