import { type ReactElement } from "react";
import { SvgDevicesLocalDesign } from "../contants";

export const SvgPhoneComp = (): ReactElement => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
        className={`${SvgDevicesLocalDesign.className} flex md:hidden`}
    >
        <g>
            <g>
                <g>
                    <path
                        d="M230.571,34.133c-4.71,0-8.491,3.823-8.491,8.533c0,4.71,3.857,8.533,8.576,8.533s8.533-3.823,8.533-8.533
				c0-4.71-3.814-8.533-8.533-8.533H230.571z"
                    />
                    <path
                        d="M238.933,460.8c0,9.412,7.654,17.067,17.067,17.067c9.412,0,17.067-7.654,17.067-17.067
				c0-9.412-7.654-17.067-17.067-17.067C246.588,443.733,238.933,451.388,238.933,460.8z"
                    />
                    <path
                        d="M128,392.533c4.71,0,8.533-3.823,8.533-8.533V42.667c0-12.442,13.158-25.6,25.6-25.6h187.733
				c12.442,0,25.6,13.158,25.6,25.6c0,4.71,3.823,8.533,8.533,8.533s8.533-3.823,8.533-8.533C392.533,20.736,371.797,0,349.867,0
				H162.133c-21.931,0-42.667,20.736-42.667,42.667V384C119.467,388.71,123.29,392.533,128,392.533z"
                    />
                    <path
                        d="M264.533,34.133c-4.71,0-8.533,3.823-8.533,8.533c0,4.71,3.823,8.533,8.533,8.533h34.133c4.71,0,8.533-3.823,8.533-8.533
				c0-4.71-3.823-8.533-8.533-8.533H264.533z"
                    />
                    <path
                        d="M332.8,153.6c2.185,0,4.369-0.836,6.033-2.5c3.337-3.337,3.337-8.73,0-12.066l-17.067-17.067
				c-3.337-3.336-8.73-3.336-12.066,0c-3.336,3.337-3.336,8.73,0,12.066l17.067,17.067C328.431,152.764,330.615,153.6,332.8,153.6z"
                    />
                    <path
                        d="M258.5,121.967c-3.336,3.337-3.336,8.73,0,12.066l68.267,68.267c1.664,1.664,3.849,2.5,6.033,2.5
				c2.185,0,4.369-0.836,6.033-2.5c3.337-3.337,3.337-8.73,0-12.066l-68.267-68.267C267.23,118.63,261.837,118.63,258.5,121.967z"
                    />
                    <path
                        d="M384,68.267H162.133c-4.71,0-8.533,3.823-8.533,8.533s3.823,8.533,8.533,8.533h213.333v384
				c0,12.442-13.158,25.6-25.6,25.6H162.133c-12.442,0-25.6-13.158-25.6-25.6v-42.667h213.333c4.71,0,8.533-3.823,8.533-8.533
				s-3.823-8.533-8.533-8.533H128c-4.71,0-8.533,3.823-8.533,8.533v51.2c0,21.931,20.736,42.667,42.667,42.667h187.733
				c21.931,0,42.667-20.736,42.667-42.667V76.8C392.533,72.09,388.71,68.267,384,68.267z"
                    />
                </g>
            </g>
        </g>
    </svg>
);
