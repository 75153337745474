import { type ThemeType } from "@/app/_utils/types";

type LangConfigType = {
    allowedThemes: ThemeType[];
    defaultTheme: ThemeType;
    storageKey: "user-theme";
};

export const themeConfig: LangConfigType = {
    allowedThemes: [
        "amber",
        "blue",
        "cyan",
        "default",
        "emerald",
        "fuchsia",
        "gray",
        "green",
        "indigo",
        "lime",
        "neutral",
        "orange",
        "pink",
        "purple",
        "red",
        "rose",
        "sky",
        "slate",
        "stone",
        "teal",
        "violet",
        "yellow",
        "zinc",
    ],
    defaultTheme: "default",
    storageKey: "user-theme",
};
